<template>
  <v-app :dark="true">
    <router-view></router-view>
    <!-- global snackbar -->
    <v-snackbar :timeout="3000" bottom right :color="snackbar.color" v-model="snackbar.show">
      {{ snackbar.text }}
      <v-btn dark text @click.native="snackbar.show = false" icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>

export default {
  components: {},
  data () {
    return {
      rightDrawer: false,
      snackbar: {
        show: false,
        text: '',
        color: ''
      }
    }
  },
  watch: {
    'store.getters.getPortfolioJson' (val) {
      if (val) {
        this.addKustomerChat()
      }
    }
  },
  mounted () {},
  created () {
    // add app events
  },
  methods: {
    addKustomerChat () {
      const portfolioParameter = JSON.parse(window.localStorage.getItem('PORTFOLIO'))
      const olarkSiteId = portfolioParameter?.olarkSiteId
      const script = document.createElement('script')
      script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js'
      script.setAttribute(
        'data-kustomer-api-key',
        'eyJhbGciOiJub25lIn0.eyJvcmciOiI2N2FjYWRkNTY2ODI0M2I3MzQ5MGY4MmQiLCJvcmdOYW1lIjoic2VydmljZWZpcnN0IiwicG9kIjoicHJvZDEiLCJyb2xlcyI6WyJvcmcudHJhY2tpbmciXX0.'
      )
      script.onload = () => {
        if (window.Kustomer) {
          window.Kustomer.start({ brandId: olarkSiteId })
        }
      }
      document.body.appendChild(script)
    }
  }
}
</script>

<style lang="sass" scoped>
.setting-fab
  top: 50% !important
  right: 0
  border-radius: 0
</style>
